import React, { useState, useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { forEach, filter, uniq, concat } from 'lodash'

import { parseACF, renderLink } from '../utils'
import { Layout, Tabs, ImageOrVideo } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage, textDot } from '../styles/global'

import { headerHeight } from '../components/Header/Header'

import { PageTitleContext } from '../context/PageTitleContext'
import { useMount } from 'react-use'

const Projects = props => {
    const { previewData, pageContext } = props
    const graphQuery = useStaticQuery(query)
    const data = previewData || parseACF(graphQuery, 'allWordpressInfopages')
    const { pageTitle, setPageTitle } = useContext(PageTitleContext)
    const [filters, setFilters] = useState([])
    const [activeFilter, setActiveFilter] = useState('all')
    const projects = data?.projects?.projects
    // console.log(activeFilter)

    const getProjectCats = (projects) => {
        if (!projects) return
    
        let cats = []
    
        forEach(projects, (project, i) => {
            if (!project.cats) return
    
            forEach(project.cats, (cat) => {
                cats.push(cat?.slug)
            })
        })
    
        return uniq(cats)
    }

    useMount(() => {
        setPageTitle(graphQuery?.allWordpressInfopages?.nodes?.[0]?.title)

        const cats = data?.projects?.cats
        let catSlugs = ['all']
    
        forEach(cats, (cat, i) => {
            if (!cat.slug) return
            catSlugs.push(cat.slug)
        })

        if (!catSlugs) return

        setFilters(catSlugs)
    })

    const filterGrid = thisFilter => {
        let results = projects

        if (thisFilter && thisFilter !== 'all') {
            results = filter(results, item => {
                const cats = getProjectCats([item])
    
                let hasCat = false
    
                if (cats.length) {
                    forEach(cats, cat => {
                        if (thisFilter === cat)
                            hasCat = true
                    })
                }
    
                return hasCat
            })
        }

        return results
    }

    const toggleFilter = (filter) => {
        setActiveFilter(filter)
    }

    const renderFilters = () => {
        if (!filters) return

        const items = filters.map((item, i) => {
            const active = activeFilter === item
            let name = item.replace(/-/g, " ");
            if (name.includes('tv')) {
                name = name.replace(/tv/g, "TV");
            }

            return (
                <Item
                    key={i}
                    className={active && 'active'}
                    onClick={() => {
                        toggleFilter(item)
                    }}
                >
                    {name}
                </Item>
            )
        })

        return (
            <Filters>
                {items}
            </Filters>
        )
    }

    const renderGrid = (item) => {
        const items = filterGrid(item)

        const gridItems = items.map((item, i) => {
            // console.log(item)
            return (
                <Item
                    as={Link}
                    key={i}
                    to={`/projects/${item.slug}/`}
                >
                    <ImageWrapper>
                    {item?.thumbnail_media && (
                        <ImageOrVideo
                            media_type={item?.thumbnail_media?.media_type}
                            image={item?.thumbnail_media?.image}
                            video={item?.thumbnail_media?.video}
                            video_embed={item?.thumbnail_media?.video_embed}
                        />
                    )}
                    {!item?.thumbnail_media && (                        
                        <Image
                            key={item.thumbnail_image.id || item.wordpress_id}
                            src={item.thumbnail_image.sizes.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <BGImage 
                                        image={src} 
                                        style={{opacity: loading ? 0 : 1}}  
                                    />
                                )
                            }}
                        </Image>
                    )}
                    </ImageWrapper>
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: item.thumbnail_text || item.title,
                        }}
                    />
                </Item>
            )
        })

        return (
            <ItemsOuter>
                {items.length > 0 && (
                    <Items>
                        {gridItems}
                    </Items>
                )}
                {items.length === 0 && (
                    <Items>
                        <Description>
                            No Results Found
                        </Description>
                    </Items>
                )}
            </ItemsOuter>
        )
    }

    const renderProjects = () => {
        return (
            <Tabs
                activeItem={activeFilter}
                items={filters}
                renderItem={renderGrid}
            />
        )
    }

    return (
        <Layout
            meta={data && data.seo}
            title={'Projects'}
        >
            <Wrapper>
                <Container>
                    {/* <PageHeader
                        heading={data?.intro_heading || 'Projects'}
                        text={data.intro_text && data.intro_text}
                    /> */}
                    {renderFilters()}
                    {renderProjects()}
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``
const Item = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`


// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
    padding-top: ${headerHeight};
`

const Container = styled.div`
    ${container};
    ${padding};
`

// Filters

const Filters = styled.div`
    display: flex;
    flex-flow: row wrap;
    margin-top: 2rem;
    padding: 1.5rem 0;
    ${tw`border-t border-darkGrey`};

    ${Item} {
        ${textDot}
        text-transform: capitalize;
        margin-right: 1rem;
        cursor: pointer;

        &.active {
            ${textDot('#000000')}
        }
    }
`

// Grid

const ItemsOuter = styled.div`
    width: 100%;
    overflow: hidden;
`

const ImageWrapper = styled.div``

const Items = styled.div`
    ${tw`flex flex-wrap`};
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    ${media.phone`
        ${tw`flex-col mx-0`};
    `}

    ${Item} {
        ${tw`w-1/4 border-darkGrey border-t border-b border-r `};
        padding: 1.5rem;

        &:nth-child(4n) {
           border-right: none;
        }

        &:nth-child(n+5) {
            border-top: none;
        }

        ${media.phone`
            ${tw`w-full px-0`};
            border: none;
        `}
    }

    .video-wrapper {
        ${tw`absolute inset-0`};
        height: 100%;
        padding: 0;
    }

    ${ImageWrapper}, .image-wrapper {
        ${tw`relative w-full`};
        height: 0;
        padding-bottom: 128.113879%;
        overflow: hidden;

        ${BGImage}, img {
            ${tw`absolute inset-0 w-full h-full`};
            object-fit: cover;
        }
    }

    ${Description} {
        margin-top: 1.5rem;

        ${media.phone`
            margin-top: 1rem;
        `}
    }

    /* no results found */
    > ${Description} {
        margin-bottom: 1.5rem;

        ${media.phone`
            margin-bottom: 1rem;
        `}
    }
`


export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "projects-page" } }) {
            nodes {
                title
                acf_json
            }
        }
    }
`

export default Projects
